/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import api from '~/services/api';
import { Container } from './styles';
import InputText from '~/components/Inputs/InputText';

import { IFormSMTP } from './types';
import ToggleDefault from '~/components/ToggleDefault';
import InputNumber from '~/components/Inputs/InputNumber';
import { CancelButton, ConfirmButton } from '~/components/Buttons';

interface FormProps {
  show: boolean;
  handleShow: (value: boolean) => void;
  handleTex: (value: any) => void;
  id: number | undefined;
}

const ModalSmtp: React.FC<FormProps> = ({
  show,
  handleShow,
  handleTex,
  id,
}) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [initInicializado, setInitInicializado] = useState(false);

  const [formSmtp, setFormSmtp] = useState<IFormSMTP>({
    des_smtp: {
      value: '',
      isInvalid: true,
      isRequired: true,
      invalidLabel: '',
    },
    num_porta: {
      value: 0,
      isInvalid: true,
      isRequired: true,
      invalidLabel: '',
    },
    flg_conexao_ssl: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: '',
    },
  });

  useEffect(() => {
    if (id) {
      getSmtp();
      setIsUpdate(true);
    } else {
      clearForm();
      setIsUpdate(false);
    }
  }, [show]);

  async function getSmtp() {
    const res = await api.get(`/smtp/${id}`);
    const { data } = res.data;
    const { des_smtp, num_porta, flg_conexao_ssl } = data;

    setFormSmtp({
      des_smtp: {
        ...formSmtp.des_smtp,
        value: des_smtp,
        isInvalid: false,
      },
      num_porta: {
        ...formSmtp.num_porta,
        value: num_porta,
        isInvalid: false,
      },
      flg_conexao_ssl: {
        ...formSmtp.flg_conexao_ssl,
        value: flg_conexao_ssl,
      },
    });
  }

  function validaFormSmtp(inputs: IFormSMTP): boolean {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formSmtp[Object.keys(inputs)[i] as keyof IFormSMTP].isRequired &&
        formSmtp[Object.keys(inputs)[i] as keyof IFormSMTP].isInvalid
      ) {
        return true;
      }
    }
    return false;
  }

  async function onSubmit() {
    if (validaFormSmtp(formSmtp)) {
      setInitInicializado(true);
      toast.warning('Há informações pendentes.');
      return;
    }
    setInitInicializado(false);
    const upsert = {
      des_smtp: formSmtp.des_smtp.value,
      num_porta: formSmtp.num_porta.value,
      flg_conexao_ssl: formSmtp.flg_conexao_ssl.value,
    };
    if (!isUpdate) {
      const res = await api.post('/smtp', upsert);
      const { message } = res.data;
      handleTex(upsert);
      handleShow(false);
      return toast.success(message);
    }
    const res = await api.put(`/smtp/${id}`, upsert);
    const { message } = res.data;
    handleShow(false);
    return toast.success(message);
  }

  function clearForm() {
    setFormSmtp({
      des_smtp: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: '',
      },
      num_porta: {
        value: 0,
        isInvalid: true,
        isRequired: true,
        invalidLabel: '',
      },
      flg_conexao_ssl: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: '',
      },
    });
  }

  function onCancel() {
    clearForm();
    handleShow(false);
  }

  return (
    <>
      {show && (
        <Modal
          show={show}
          onHide={() => handleShow(false)}
          className="modal-fade absolute inset-0 overflow-auto bg-gray-50"
          id="modalEmailServidorEntrada"
          centered
          size="lg"
        >
          <Modal.Header>
            <Modal.Title id="modalEmailServidorEntrada">
              Cadastrar Servidor SMTP
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12 col-md-10">
                <InputText
                  label="SMTP"
                  placeholder=""
                  maxLength={100}
                  iniInicializado={initInicializado}
                  isRequired={formSmtp.des_smtp.isRequired}
                  setInvalid={formSmtp.des_smtp.isInvalid}
                  onChange={(value: string) => {
                    setFormSmtp({
                      ...formSmtp,
                      des_smtp: {
                        ...formSmtp.des_smtp,
                        value,
                        isInvalid: value === '',
                      },
                    });
                  }}
                  value={formSmtp.des_smtp.value || ''}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputNumber
                  label="Porta"
                  placeholder=""
                  maxLength={100}
                  iniInicializado={initInicializado}
                  isRequired={formSmtp.num_porta.isRequired}
                  setInvalid={formSmtp.num_porta.isInvalid}
                  onChange={(value: number) => {
                    setFormSmtp({
                      ...formSmtp,
                      num_porta: {
                        ...formSmtp.num_porta,
                        value,
                        isInvalid: value === 0,
                      },
                    });
                  }}
                  value={formSmtp.num_porta.value || 0}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="mt-3">
            <ToggleDefault
              labelText="Utilizar Conexão Segura SSL/TLS"
              setChecked={formSmtp.flg_conexao_ssl.value}
              onSwitch={() =>
                setFormSmtp({
                  ...formSmtp,
                  flg_conexao_ssl: {
                    ...formSmtp.flg_conexao_ssl,
                    value: !formSmtp.flg_conexao_ssl.value,
                  },
                })
              }
            />
            <div className="d-flex col justify-content-end">
              <ConfirmButton onClick={() => onSubmit()}>
                Confirmar
              </ConfirmButton>
              <CancelButton onClick={() => onCancel()}>Cancelar</CancelButton>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ModalSmtp;
