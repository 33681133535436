import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .btn-actions,
  .btn-actions:focus {
    border: none;
    outline: none;
    background-color: transparent;
  }

  .field-error {
    border-radius: 4px;
    border-bottom: 2px solid #cf1919;
    transition: 0.5s;
  }

  .input-icon {
    position: relative;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding: 10px;
      border-radius: 3px;
      height: 100%;
      width: 35px;
      font-size: 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    .icon-left {
      left: 0;
      top: 0;
    }

    .icon-right {
      right: 0;
      top: 0;
    }

    .field-error {
      border-radius: 4px;
      border-bottom: 2px solid #cf1919;
      transition: 0.5s;
    }
  }
`;

export const StyledForm = styled.form`
  /* margin-top: 20px; */
  padding: 20px;
  border-radius: 5px;
  /* display: flex; */
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  outline: none;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export interface SelectProps {
  label: string | number | undefined;
  value: number | undefined | string;
}
